<template>
  <div class="home-con">
    <van-form @submit="onSubmit" style="margin-top:10px">
      <van-field name="search_type" label="搜索类型">
        <template #input>
          <van-radio-group v-model="config.search_type" direction="horizontal">
            <van-radio name="username">用户名</van-radio>
            <van-radio name="email">邮箱</van-radio>
            <van-radio name="taobao_account">下单账号</van-radio>
            <van-radio name="activation_code">激活码</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="config.key"
        name="key"
        label="关键字"
        placeholder="输入关键字"
        :rules="[{ required: true, message: '请填写关键字' }]"
      />

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">获取</van-button>
      </div>
    </van-form>

    <div v-show="user.username">
      <van-cell-group title="用户信息" title-class="cell-title">
        <van-cell title-class="cell-title" center title="用户名" :value="user.username" is-link @click="show_modify_username = true" />
        <van-cell title-class="cell-title" center title="密码" :value="user.password" is-link @click="show_modify_password = true" />
        <van-cell title-class="cell-title" center title="邮箱" :value="user.email" is-link @click="show_modify_email = true" />
        <van-cell title-class="cell-title" center title="下单账号" :value="user.taobao_account" />
        <van-cell title-class="cell-title" center title="允许设备数(win32)" is-link :value="user.allow_device_num" @click="show_allow_device_num=true" />
        <van-cell title-class="cell-title" center title="允许设备数(darwin)" is-link :value="user.allow_device_num_darwin" @click="show_allow_device_num_darwin=true" />
        <van-cell title-class="cell-title" center title="允许平台数" is-link :value="user.platform_num" @click="show_platform_num=true"/>
        <van-cell title-class="cell-title" center title="绑定激活码" :value="user.activation_code" />
        <van-cell title-class="cell-title" center title="激活时长" :value="user.time_limit" />
        <van-cell title-class="cell-title" center title="已经使用时长" :value="remainTime(user.used_time)" />
        <van-cell title-class="cell-title" center title="注册时间" :value="user.registered_time" />
        <van-cell title-class="cell-title" center title="最近登录时间" :value="user.recent_login_time" />
        <van-cell title-class="cell-title" center title="注册平台" :value="user.register_platform" />
      </van-cell-group>
    </div>
    <div v-show="devices_list.length>0">
      <van-cell-group title="设备列表" title-class="cell-title">
        <template v-for="(item,index) in devices_list">
          <van-cell :key="index" title-class="cell-title" center :title="item.platform+'/'+item.time" is-link 
          :value="item.m_model_identifier+'/'+item.w_computer_name" @click="deleteDev(item)" />
        </template>
      </van-cell-group>
    </div>
    <!-- 激活码信息 -->
    <div v-show="code_info.platform">
      <van-cell-group title="激活码信息" title-class="cell-title">
          <van-cell title-class="cell-title" center :title="'时间 '+ (code_info.time_limit==0?'永久':code_info.time_limit) + ' /平台 '+code_info.platform+ ' /设备数 '+ code_info.allow_device_num" 
          :value="code_info.is_used==1?'已使用':'未使用'"
          :label="code_info.generate_time_str +'/'+ code_info.used_time_str"
           />
      </van-cell-group>
    </div>

    <van-popup v-model="show_allow_device_num" position="bottom" :style="{ height: '20%',paddingTop:'10%',textAlign:'center' }">
      <van-stepper v-model="user.allow_device_num" theme="round"/>
      <van-button plain round type="primary" style="margin-top:20px" @click="modifyADN">确定</van-button>
    </van-popup>
    <van-popup v-model="show_allow_device_num_darwin" position="bottom" :style="{ height: '20%',paddingTop:'10%',textAlign:'center' }">
      <van-stepper v-model="user.allow_device_num_darwin" theme="round"/>
      <van-button plain round type="primary" style="margin-top:20px" @click="modifyADND">确定</van-button>
    </van-popup>
    <van-popup v-model="show_platform_num" position="bottom" :style="{ height: '20%',paddingTop:'10%',textAlign:'center' }">
      <van-stepper v-model="user.platform_num" theme="round"/>
      <van-button plain round type="primary" style="margin-top:20px" @click="modifyPN">确定</van-button>
    </van-popup>
    <!-- 修改用户名密码邮箱 -->
    <van-popup v-model="show_modify_username" position="center" :style="{width:'100%', height: '20%',paddingTop:'10%',textAlign:'center' }">
      <van-field v-model="user.username" label="文本" placeholder="请输入用户名" />
      <van-button plain round type="primary" style="margin-top:20px" @click="modifyUB">确定</van-button>
    </van-popup>
    <van-popup v-model="show_modify_password" position="center" :style="{width:'100%', height: '20%',paddingTop:'10%',textAlign:'center' }">
      <van-field v-model="user.password" label="文本" placeholder="请输入用户名" />
      <van-button plain round type="primary" style="margin-top:20px" @click="modifyUB">确定</van-button>
    </van-popup>
    <van-popup v-model="show_modify_email" position="center" :style="{width:'100%', height: '20%',paddingTop:'10%',textAlign:'center' }">
      <van-field v-model="user.email" label="文本" placeholder="请输入用户名" />
      <van-button plain round type="primary" style="margin-top:20px" @click="modifyUB">确定</van-button>
    </van-popup>
  </div>
</template>

<script>
import api from "@/api";
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
  name: 'UserDevices',
  data(){
    return {
      codes_show:false,
      show_allow_device_num:false,
      show_allow_device_num_darwin:false,
      show_platform_num:false,
      show_modify_username:false,
      show_modify_password:false,
      show_modify_email:false,
      config:{
        search_type:'username',
        key:'',
      },
      code_info:{},
      devices_list:[],
      user:{},
      subV:null //上次提交查询内容
    }
  },
  components: {

  },
  methods:{
    remainTime(used_time = 0){
      if(used_time && !isNaN(used_time) && used_time!=0){
        let time =(new Date).getTime()/1000 - parseInt( used_time )
        if (null != time && "" != time) {
            if (time > 60 && time < 60 * 60) {
                time = parseInt(time / 60.0) + "分钟" + parseInt((parseFloat(time / 60.0) -
                    parseInt(time / 60.0)) * 60) + "秒";
            }
            else if (time >= 60 * 60 && time < 60 * 60 * 24) {
                time = parseInt(time / 3600.0) + "小时" + parseInt((parseFloat(time / 3600.0) -
                    parseInt(time / 3600.0)) * 60) + "分钟" +
                    parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
                    parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
            } else if (time >= 60 * 60 * 24) {
                time = parseInt(time / 3600.0/24) + "天" +parseInt((parseFloat(time / 3600.0/24)-
                    parseInt(time / 3600.0/24))*24) + "小时" + parseInt((parseFloat(time / 3600.0) -
                    parseInt(time / 3600.0)) * 60) + "分钟" +
                    parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
                    parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) + "秒";
            }
            else {
                time = parseInt(time) + "秒";
            }
        }
        return time;
      }
      return '未知';
    },
    onClickLeft(){
      this.$toast('点左侧')
      this.$router.go(-1)
    },
    onClickRight(){
      this.$toast('点右侧')
    },
    copyCode(index,code){
      const input = document.getElementById(`input${index}`);
      input.value = code;
      // 聚焦
      input.focus();
      // 选择需要复制的文本
      if (input.setSelectionRange) {
          input.setSelectionRange(0, input.value.length);
      } else {
          input.select();
      }
      try {
          const result = document.execCommand('copy');
          if(result){
            this.$toast.success('复制激活码成功')
          }
      } catch (e) {
        this.$toast.fail('复制失败，请重试~')
      }
    },
    onSubmit(values) {
      this.subV = values
      Toast('提示内容');
      api.devices.getUserDevices(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            Toast.success('获取成功');
            this.devices_list = r.data.devices || []
            this.user = r.data.user || {}
            this.code_info = r.data.code_info || {}
          }else{
              console.log(res)
              Toast.fail('获取失败');
          }
      }).catch(err=>{
        Toast.fail('获取失败');
      })
    },
    //修改win32平台设备数
    modifyADN() {
      let values = {allow_device_num:this.user.allow_device_num,id:this.user.id}
      //values['token'] = 'lizheng666'
      api.user.modifyUserInfo(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            Toast.success('修改成功');
            if(this.subV) this.onSubmit(this.subV)
          }else{
              Toast.fail('修改失败');
          }
          this.show_allow_device_num = false
      }).catch(err=>{
        console.error(err)
        Toast.fail('修改失败');
        this.show_allow_device_num = false
      })
    },
    //修改苹果平台设备数
    modifyADND() {
      let values = {allow_device_num_darwin:this.user.allow_device_num_darwin,id:this.user.id}
      //values['token'] = 'lizheng666'
      api.user.modifyUserInfo(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            Toast.success('修改成功');
            if(this.subV) this.onSubmit(this.subV)
          }else{
              Toast.fail('修改失败');
          }
          this.show_allow_device_num_darwin = false
      }).catch(err=>{
        console.error(err)
        Toast.fail('修改失败');
        this.show_allow_device_num_darwin = false
      })
    },
    //修改平台数
    modifyPN() {
      let values = {platform_num:this.user.platform_num,id:this.user.id}
      //values['token'] = 'lizheng666'
      api.user.modifyUserInfo(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            Toast.success('修改成功');
            if(this.subV) this.onSubmit(this.subV)
          }else{
              Toast.fail('修改失败');
          }
          this.show_platform_num = false
      }).catch(err=>{
        console.error(err)
        Toast.fail('修改失败');
        this.show_platform_num = false
      })
    },
    //修改用户基本信息
    modifyUB(){
      let values = { username:this.user.username,
      password:this.user.password, email:this.user.email, id:this.user.id}
      // //values['token'] = 'lizheng666'
      api.user.modifyUserInfo(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            Toast.success('修改成功');
            if(this.subV) this.onSubmit(this.subV)
          }else{
              Toast.fail('修改失败');
          }
          this.show_platform_num = false
      }).catch(err=>{
        console.error(err)
        Toast.fail('修改失败');
        this.show_platform_num = false
      })
    },
    deleteDev(item){
      //删除设备绑定
      Dialog.confirm({
        title: `确认删除?${item.platform}/${item.time}`,
        message: `${item.m_model_identifier}/${item.w_computer_name}`,
      })
        .then(() => {
          let values = {dev_id:item.id}
          //values['token'] = 'lizheng666'
          api.devices.deleteDev(values).then(res=>{
              let r = res.data;
              if(r.code == 1){
                console.log(res)
                Toast.success('删除成功');
                if(this.subV) this.onSubmit(this.subV)
              }else{
                  Toast.fail('获取失败');
              }
          }).catch(err=>{
            Toast.fail('删除失败');
          })
        })
        .catch(() => {
          // on cancel
        });
    }
  }
}
</script>
<style lang="scss" scoped>
.home-con{
  .cell-title{
    text-align: left;
  }

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
  .van-radio{
    margin-bottom:5px
  }
  .copy-input{
    width: 1px;
    resize: none;
    border: none;
    outline: none;
    user-select: none;
    color: transparent;
    background: transparent;
  }
}
</style>